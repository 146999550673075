import { AsyncPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule, DateFormat, FilterMatchMode } from '@capturum/ui/api';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CalendarFilterConfigItem, DynamicFilterComponent } from '../../interfaces/dynamic-filter.interface';
import { DateFilterSelectionModePipe } from '../../pipes/date-filter-selection-mode.pipe';
import { CapturumDynamicFilterLayoutComponent } from '../dynamic-filter-layout/dynamic-filter-layout.component';

type CalendarValue = string[] | string;

@Component({
  selector: 'cap-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CapturumDynamicFilterLayoutComponent,
    CapturumCalendarModule,
    ReactiveFormsModule,
    AsyncPipe,
    CapturumSharedModule,
    DateFilterSelectionModePipe,
  ],
  providers: [DatePipe],
})
export class CalendarFilterComponent implements DynamicFilterComponent<CalendarValue> {
  public updateValue: EventEmitter<CalendarValue> = new EventEmitter<CalendarValue>();
  @Input()
  public filterConfig: CalendarFilterConfigItem;

  public formControl = new FormControl();

  constructor(
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) {}

  public setValue(value: CalendarValue): void {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.setValue(null);
    this.updateValue.emit(this.formControl.value);
  }

  public handleDateFilterChange(filterMatchMode: FilterMatchMode | string): void {
    if (filterMatchMode === FilterMatchMode.BETWEEN || filterMatchMode === FilterMatchMode.DATEBETWEEN) {
      const dateValues: string[] = this.formControl.value.map((date) => {
        return this.datePipe.transform(date, DateFormat.generalReverse);
      });

      if (dateValues.length === 2 && dateValues.every(Boolean)) {
        this.updateValue.emit(dateValues);
      }
    } else {
      this.updateValue.emit(this.datePipe.transform(this.formControl.value, DateFormat.generalReverse));
    }

    this.cdr.detectChanges();
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
